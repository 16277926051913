import { PUBLIC_CLERK_PUBLISHABLE_KEY, PUBLIC_ENVIRONMENT } from '$env/static/public';
import * as Sentry from '@sentry/sveltekit';
import { Replay, handleErrorWithSentry } from '@sentry/sveltekit';
import type { HandleClientError } from '@sveltejs/kit';
import { initializeClerkClient } from 'clerk-sveltekit/client';

Sentry.init({
	dsn: 'https://d5060f29830b00a4b13f4a7762be256e@o4506105777225728.ingest.sentry.io/4506105850626048',
	tracesSampleRate: 1.0,

	// This sets the sample rate to be 10%. You may want this to be 100% while
	// in development and sample at a lower rate in production
	replaysSessionSampleRate: 0.1,

	// If the entire session is not sampled, use the below sample rate to sample
	// sessions when an error occurs.
	replaysOnErrorSampleRate: 1.0,

	// If you don't want to use Session Replay, just remove the line below:
	integrations: [
		new Replay({
			maskAllInputs: false,
			blockAllMedia: false
		})
	],
	environment: PUBLIC_ENVIRONMENT
});

initializeClerkClient(PUBLIC_CLERK_PUBLISHABLE_KEY, {
	afterSignInUrl: '/dashboard/',
	afterSignUpUrl: '/dashboard/',
	signInUrl: '/sign-in',
	signUpUrl: '/sign-up'
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError: HandleClientError = handleErrorWithSentry();
